export const routeMappings: Record<string, string> = {
  '001': 'admin',
  '002': 'clientes',
  '003': 'sectores',
  '004': 'empleados',
  '005': 'tipoCliente',
  '006': 'instituciones',
  '007': 'valores',
  '008': 'basico',
  '009': 'pago',
  '010': 'users',
  '011': 'medidores',
}
