export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}
export const getMenuRoutes = (): INavigationRoute[] => {
  try {
    const menu = JSON.parse(localStorage.getItem('menu') || '[]') as INavigationRoute[]
    return menu
  } catch (error) {
    console.error('Error parsing menu from localStorage:', error)
    return []
  }
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: getMenuRoutes(), // Dynamically load the routes from localStorage
}
