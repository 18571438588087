<template>
  <div id="app">
    <RouterView />
    <BottomNavigation v-if="showBottomNavigation" />
  </div>
</template>

<script setup lang="ts">
import { computed, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import BottomNavigation from './components/sidebar/BottomNavigation.vue'

const route = useRoute()

// Define the list of routes where the BottomNavigation should be hidden
const hiddenRoutes = ['login', 'signup', 'recover-password', 'recover-password-email']

// Determine if the BottomNavigation should be shown based on the current route name
const showBottomNavigation = computed(() => {
  // Ensure the route name is being accessed correctly
  if (route.name && typeof route.name === 'string') {
    return !hiddenRoutes.includes(route.name)
  }
  return true // Default to showing the bottom navigation if route name is not available
})

// Watch for changes in the route and log the current route name
watchEffect(() => {
  console.log('Current route name:', route.name)
  console.log('Is BottomNavigation visible?', showBottomNavigation.value)
})
</script>

<style lang="scss">
@import 'scss/main.scss';

#app {
  font-family: 'Inter', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  min-width: 20rem;
}
</style>
