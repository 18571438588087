<!-- BottomNavigation.vue -->
<template>
  <div class="bottom-bar">
    <input
      v-model="transactionNumber"
      type="text"
      placeholder="Ingrese #transacción (Ej: '001')"
      @keyup.enter="navigate"
    />
    <button @click="navigate">Ir</button>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useModal } from 'vuestic-ui'
import { useRouter } from 'vue-router'
import { routeMappings } from '../../data/routeMappings' // Import the route mappings

const transactionNumber = ref<string>('') // Define transactionNumber with a type
const router = useRouter()

const { confirm } = useModal()

const navigate = async (): Promise<void> => {
  // Find the route name based on the transaction ID
  const routeName = routeMappings[transactionNumber.value]

  if (routeName) {
    router.push({ name: routeName })
  } else {
    await confirm({
      title: 'Alerta',
      message: `Trasacción Inválida (Ej. 001)`,
      okText: 'Aceptar',
      cancelText: '',
      size: 'small',
      maxWidth: '380px',
    })
  }

  // Clear the input field
  transactionNumber.value = ''
}
</script>

<style scoped>
.bottom-bar {
  position: fixed;
  bottom: 10px; /* Adjust this value as needed */
  left: 5px; /* Align the bar to the left */
  width: calc(100% - 260px); /* Adjust 260px according to your sidebar width */
  max-width: 290px; /* Set a maximum width for the bottom bar */
  padding: 0px;
  background-color: #f0f0f0;
  text-align: center;
  display: flex;
  justify-content: flex-start; /* Align items to the left */
  gap: 10px;
  border-radius: 8px; /* Add some rounding to the corners if desired */
}

input {
  padding: 5px;
  width: 100%; /* Adjust input width */
  font-size: 14px; /* Reduce the font size */
}

button {
  padding: 5px 10px;
  font-size: 14px; /* Reduce the font size */
  cursor: pointer;
}
</style>
